"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _RoundButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/RoundButton"));
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _Img = _interopRequireDefault(require("../Img"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _reactScrollbooster = require("react-scrollbooster");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _usePrevious = _interopRequireDefault(require("../../hooks/usePrevious"));
var _createImgProps = _interopRequireDefault(require("../../utils/createImgProps"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const isCategories = images => images.some(img => 'translatedCategoryName' in img);
const PhotoModal = ({
  imagesOrCategories,
  open,
  startIndex,
  onClose,
  hideCount
}) => {
  const [scrollbooster] = (0, _reactScrollbooster.useScrollBoost)({
    direction: 'horizontal',
    pointerMode: 'mouse',
    scrollMode: 'native'
  });
  const {
    0: nextEnabled,
    1: setNextEnabled
  } = (0, _react.useState)(true);
  const {
    0: prevEnabled,
    1: setPrevEnabled
  } = (0, _react.useState)(true);
  const viewportNode = (0, _react.useRef)();
  const onScroll = (0, _react.useCallback)(() => {
    const vpElement = viewportNode.current;
    if (vpElement) {
      setNextEnabled(Math.round(vpElement.scrollLeft + vpElement.clientWidth) !== vpElement.scrollWidth);
      setPrevEnabled(vpElement.scrollLeft > 0);
    }
  }, []);
  const viewport = (0, _react.useCallback)(node => {
    viewportNode.current = node;
    scrollbooster(node);
    node?.addEventListener('scroll', onScroll);
    onScroll();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  // scroll to the index when opening the modal
  const previousOpen = (0, _usePrevious.default)(open);
  (0, _react.useEffect)(() => {
    if (previousOpen && open) {
      document.getElementById(`photo-gallery-${startIndex}`)?.scrollIntoView({
        inline: 'nearest'
      });
    }
  }, [open, previousOpen, startIndex]);
  const onSlide = (0, _react.useCallback)(({
    direction = 'next'
  }) => {
    const w = window.innerWidth;
    const imgNodes = Array.from(viewportNode.current?.getElementsByClassName('photo-gallery-image') ?? []);
    let closestEl;
    imgNodes.forEach((img, i) => {
      const rect = img.getBoundingClientRect();
      const absMidX = Math.abs(rect.x + rect.width / 2 - w / 2);
      if (!closestEl || closestEl.absMidX > absMidX) {
        closestEl = {
          absMidX,
          width: rect.width,
          imgNodesIndex: i,
          img
        };
      }
    });
    if (closestEl) {
      imgNodes?.[closestEl.imgNodesIndex + (direction === 'next' ? 1 : -1)]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center'
      });
    }
  }, []);
  return __jsx(ModalWithOverflow, {
    open: open,
    onClose: onClose,
    variant: "full"
  }, __jsx(Viewport, {
    ref: viewport,
    withCategories: isCategories(imagesOrCategories)
  }, isCategories(imagesOrCategories) && __jsx(Content, null, Object.keys(imagesOrCategories).map((_, categoryIndex) => __jsx(ImageCategory, {
    key: categoryIndex,
    id: `photo-gallery-${categoryIndex}`
  }, __jsx(Label, null, __jsx(_Title.default, {
    variant: "tiny"
  }, imagesOrCategories[categoryIndex].translatedCategoryName), !hideCount && __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "myJ8mF",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "count"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "count",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "foto"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "foto's"
          }]
        }
      }
    }],
    values: {
      count: imagesOrCategories[categoryIndex].images.length
    }
  }))), __jsx(Images, {
    images: imagesOrCategories[categoryIndex].images
  })))), !isCategories(imagesOrCategories) && __jsx(Content, null, __jsx(ImagesContainer, null, __jsx(Images, {
    setId: true,
    images: imagesOrCategories
  })))), __jsx(CarouselNav, null, __jsx(LeftButton, {
    disabled: !prevEnabled,
    variant: "outline",
    onClick: () => {
      onSlide({
        direction: 'prev'
      });
    }
  }, __jsx(_ArrowLeft.default, null)), __jsx(RightButton, {
    disabled: !nextEnabled,
    variant: "outline",
    onClick: () => {
      onSlide({
        direction: 'next'
      });
    }
  }, __jsx(_ArrowRight.default, null))));
};
const Images = ({
  images,
  setId
}) => __jsx(StyledImages, null, images.map((image, imageIndex) => {
  if (!image.jpeg || !image.webp) {
    return null;
  }
  return __jsx(Photo, {
    key: imageIndex,
    ratio: image?.jpeg?.ratio,
    id: setId ? `photo-gallery-${imageIndex}` : undefined
  }, __jsx("svg", {
    viewBox: `0 0 ${image?.jpeg?.ratio} 1`
  }), __jsx(_Img.default, (0, _extends2.default)({
    layout: "fill",
    loading: "lazy"
  }, (0, _createImgProps.default)(image.jpeg, image.webp, '100vw', {
    className: 'photo-gallery-image'
  }))));
}));
const ModalWithOverflow = (0, _styledComponents.default)(_Modal.default).withConfig({
  displayName: "PhotoModal__ModalWithOverflow",
  componentId: "b6bwxj-0"
})(["height:100vh;--gutter:3.2rem;@supports (width:clamp(1px,1px,1px)){--gutter:clamp(3.2rem,5vw,8rem);}"]);
const Viewport = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__Viewport",
  componentId: "b6bwxj-1"
})(["overflow-y:scroll;margin:-2rem;padding:5.6rem 2rem;height:100vh;position:relative;@media (min-width:", "){overflow-y:hidden;overflow-x:scroll;position:absolute;height:auto;top:0;left:0;right:0;bottom:0;padding:8rem 0 ", ";--viewport:100vh - 8rem - 4rem;margin:0;cursor:move;cursor:grab;&:active{cursor:grabbing;}}"], ({
  theme
}) => theme.mediaQueries.s, ({
  withCategories
}) => withCategories ? '4rem' : '12rem');
const Content = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__Content",
  componentId: "b6bwxj-2"
})(["> * + *{border-top:1px solid ", ";}@media (min-width:", "){display:flex;align-items:stretch;height:100%;> * + *{border:none;border-left:1px solid ", ";}}@media (max-width:", "px){padding-bottom:", ";}"], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.mediaQueriesValues.s - 1, ({
  theme
}) => theme.spacing['70_XLarge']);
const ImagesContainer = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__ImagesContainer",
  componentId: "b6bwxj-3"
})(["padding:var(--gutter) 0;@media (min-width:", "){padding:0 var(--gutter);align-items:flex-end;justify-items:flex-start;height:100%;--category:4rem;> div{height:100%;> div{height:100%;}}}"], ({
  theme
}) => theme.mediaQueries.s);
const ImageCategory = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__ImageCategory",
  componentId: "b6bwxj-4"
})(["padding:var(--gutter) 0;@media (min-width:", "){flex:0;display:grid;--category:8.8rem;grid-template-rows:calc(100% - var(--category)) 8rem;grid-template-areas:'images' 'category';align-items:flex-end;justify-items:flex-start;padding:0 var(--gutter);}"], ({
  theme
}) => theme.mediaQueries.s);
const StyledImages = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__StyledImages",
  componentId: "b6bwxj-5"
})(["margin-top:", ";@media (max-width:", "px){> * + *{margin-top:var(--gutter);}}@media (min-width:", "){display:flex;margin:0;width:max-content;> * + *{margin-left:var(--gutter);}}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueriesValues.s, ({
  theme
}) => theme.mediaQueries.s);
const Label = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__Label",
  componentId: "b6bwxj-6"
})(["@media (min-width:", "){grid-area:category;position:sticky;left:4rem;margin:0;}"], ({
  theme
}) => theme.mediaQueries.s);
const Photo = _styledComponents.default.div.withConfig({
  displayName: "PhotoModal__Photo",
  componentId: "b6bwxj-7"
})(["position:relative;max-width:100vw;flex:0;svg{object-fit:contain;@media (min-width:", "){height:calc( var(--viewport) - var(--category) );}}figure{position:absolute;top:0;left:0;width:100%;height:100%;}img{will-change:transform,opacity;object-fit:cover;}"], ({
  theme
}) => theme.mediaQueries.s);
const CarouselNav = _styledComponents.default.nav.withConfig({
  displayName: "PhotoModal__CarouselNav",
  componentId: "b6bwxj-8"
})(["position:absolute;bottom:5rem;right:3.2rem;z-index:", ";> * + *{margin-left:0.8rem;}@media (max-width:", "){display:none;}"], ({
  theme
}) => theme.zIndices.modal, ({
  theme
}) => theme.mediaQueries.s);
const LeftButton = (0, _styledComponents.default)(_RoundButton.default).withConfig({
  displayName: "PhotoModal__LeftButton",
  componentId: "b6bwxj-9"
})(["background:white;opacity:", ";"], ({
  disabled
}) => disabled ? '0.4' : '1');
const RightButton = (0, _styledComponents.default)(LeftButton).withConfig({
  displayName: "PhotoModal__RightButton",
  componentId: "b6bwxj-10"
})(["background:white;opacity:", ";"], ({
  disabled
}) => disabled ? '0.4' : '1');
var _default = exports.default = PhotoModal;