"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Page = _interopRequireDefault(require("./Page"));
var _react = _interopRequireDefault(require("react"));
var _Section = _interopRequireDefault(require("./designsystem/Section"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _grid = require("@rebass/grid");
var _Stack = _interopRequireDefault(require("./Stack"));
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _Loading = _interopRequireDefault(require("./Loading"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var __jsx = _react.default.createElement;
const LoadingDetail = ({
  withHero
}) => __jsx(_Page.default, {
  style: {
    position: 'relative'
  }
}, withHero && __jsx(SkeletonHero, null), __jsx(_Section.default, null, __jsx(_ContentWrapper.default, null, __jsx(_grid.Box, {
  width: 0.6
}, __jsx(_Stack.default, {
  spacing: 2,
  mb: 5
}, __jsx(_reactLoadingSkeleton.default, {
  width: "10rem",
  height: "50"
}), __jsx(_reactLoadingSkeleton.default, {
  width: "10rem",
  height: "50"
})), __jsx(_Loading.default, {
  spacing: 2
})))), __jsx(SkeletonHero, {
  className: "lt-s"
}));
var _default = exports.default = LoadingDetail;
const SkeletonHero = ({
  className
}) => __jsx(SkeletonWrapper, {
  className: className,
  ratio: 1008 / 1920
}, __jsx(_grid.Box, {
  width: 1,
  height: "100%",
  style: {
    position: 'absolute',
    top: 0,
    left: 0
  }
}, __jsx(_reactLoadingSkeleton.default, {
  width: "100%",
  height: "100%"
})));
const SkeletonWrapper = _styledComponents.default.div.withConfig({
  displayName: "LoadingDetail__SkeletonWrapper",
  componentId: "uhltzw-0"
})(["position:relative;max-height:calc(100vh - 27.2rem);&:before{display:block;content:'';width:100%;padding-top:", "%;}"], ({
  ratio
}) => Math.round(ratio * 100));