"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.HeroPhotosButton = exports.HeroContainer = exports.HeroButtonsMobile = exports.HeroButtonsDesktop = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Img = _interopRequireDefault(require("./Img"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _DoubleLandscape = _interopRequireDefault(require("./svg/DoubleLandscape.svg"));
const _excluded = ["ratio", "className", "children", "sources"],
  _excluded2 = ["children"];
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const Hero = _ref => {
  let {
      ratio,
      className,
      children,
      sources
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(HeroWrapper, {
    ratio: ratio,
    className: className
  }, __jsx(_Img.default, (0, _extends2.default)({
    loading: "eager",
    layout: "fill",
    sources: sources?.map(source => _objectSpread(_objectSpread({}, source), {}, {
      sizes: '100vw'
    }))
  }, props)), children);
};
var _default = exports.default = Hero;
const HeroButtonsMobile = exports.HeroButtonsMobile = _styledComponents.default.div.withConfig({
  displayName: "Hero__HeroButtonsMobile",
  componentId: "sc-19b0m76-0"
})(["display:flex;position:absolute;bottom:1.2rem;right:1.2rem;z-index:1;> button{:not(:first-of-type){margin-left:0.4rem;}}@media (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.xs);
const HeroButtonsDesktop = exports.HeroButtonsDesktop = _styledComponents.default.div.withConfig({
  displayName: "Hero__HeroButtonsDesktop",
  componentId: "sc-19b0m76-1"
})(["display:none;@media (min-width:", "){display:flex;position:absolute;left:50%;bottom:3.2rem;transform:translateX(-50%);z-index:1;}"], ({
  theme
}) => theme.mediaQueries.xs);
const HeroPhotosButton = _ref2 => {
  let {
      children
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return __jsx(ViewPhotosButton, (0, _extends2.default)({
    size: "small"
  }, props), __jsx(_DoubleLandscape.default, null), __jsx(_Body.default, {
    variant: "small"
  }, children));
};
exports.HeroPhotosButton = HeroPhotosButton;
const HeroWrapper = _styledComponents.default.div.withConfig({
  displayName: "Hero__HeroWrapper",
  componentId: "sc-19b0m76-2"
})(["position:relative;height:calc(-26rem + 100vh);max-height:40vh;@media (min-width:", "){max-height:65vh;}&:before{display:block;content:'';width:100%;padding-top:", "%;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  ratio
}) => Math.round(ratio * 100));
const ViewPhotosButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "Hero__ViewPhotosButton",
  componentId: "sc-19b0m76-3"
})(["background:rgba(0,0,0,0.5);border-radius:0.8rem;border:none;box-shadow:none;z-index:1;@media (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.xs);
const HeroContainer = exports.HeroContainer = _styledComponents.default.div.withConfig({
  displayName: "Hero__HeroContainer",
  componentId: "sc-19b0m76-4"
})(["position:relative;min-height:80px;"]);