"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTileImageSizes = exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Img = _interopRequireDefault(require("../Img"));
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _createImgProps = _interopRequireDefault(require("../../utils/createImgProps"));
const _excluded = ["jpeg", "webp"];
var __jsx = _react.default.createElement;
const useTileImageSizes = () => {
  const {
    mediaQueries
  } = (0, _useThemeContext.useThemeContext)();
  return `(min-width: ${mediaQueries.m}) 447px, 320px`;
};
exports.useTileImageSizes = useTileImageSizes;
const Tile = _ref => {
  let {
      jpeg,
      webp
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const sizes = useTileImageSizes();
  return __jsx(StyledTile, (0, _createImgProps.default)(jpeg, webp, sizes, props));
};
const StyledTile = (0, _styledComponents.default)(_Img.default).withConfig({
  displayName: "Tile__StyledTile",
  componentId: "sc-1oj9iv7-0"
})(["margin:0;width:32rem;height:24rem;position:relative;@media (min-width:", "){width:44.8rem;height:33.6rem;}"], ({
  theme
}) => theme.mediaQueries.m);
var _default = exports.default = Tile;