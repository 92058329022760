"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.devMarkerUrl2x = exports.devMarkerUrl = exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var _map = require("../constants/map");
const _excluded = ["iconUrl", "iconUrl2x"];
var __jsx = _react.default.createElement;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const StaticMap = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('@oberoninternal/travelbase-ds/components/misc/StaticMap'))), {
  ssr: false,
  loadableGenerated: {
    webpack: () => [require.resolveWeak('@oberoninternal/travelbase-ds/components/misc/StaticMap')]
  }
});
// const isDev = process.env.NODE_ENV !== 'production';
// can't ask mapbox to download images hosted on localhost of course, so these are fallbacks.
// also we can't use the origin in order to use the static images in de public folder because of that the test env is password protected, so:
// TODO: replace fallbacks with origin urls

const devMarkerUrl = exports.devMarkerUrl = 'https://i.imgur.com/qEhX1Ah.png';
const devMarkerUrl2x = exports.devMarkerUrl2x = 'https://i.imgur.com/QwAO0Qp.png';
const withDevFallback = (url, fallback) => process.env.NODE_ENV === 'development' ? fallback : url;
const LocationMap = _ref => {
  let {
      iconUrl,
      iconUrl2x
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    mapZoom,
    mapMinZoom
  } = brandConfig;
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const mapMinZoomDeviceSize = (deviceSize === 'laptop' || deviceSize === 'desktop' ? mapMinZoom?.desktop : mapMinZoom?.mobile) ?? _map.ZOOM_MIN;
  return __jsx(StyledStaticMap, (0, _extends2.default)({
    iconUrl: iconUrl ? withDevFallback(iconUrl, devMarkerUrl) : devMarkerUrl,
    iconUrl2x: iconUrl2x ? withDevFallback(iconUrl2x, devMarkerUrl2x) : devMarkerUrl2x,
    showMapText: __jsx(_reactIntl.FormattedMessage, {
      id: "Y3gC6W",
      defaultMessage: [{
        "type": 0,
        "value": "Kaart tonen"
      }]
    }),
    zoom: mapZoom ?? _map.ZOOM_DEFAULT,
    minZoom: mapMinZoomDeviceSize,
    mapboxApiAccessToken: process.env.NEXT_PUBLIC_MAPBOX_KEY ?? ''
  }, props));
};
var _default = exports.default = LocationMap;
const StyledStaticMap = (0, _styledComponents.default)(StaticMap).withConfig({
  displayName: "LocationMap__StyledStaticMap",
  componentId: "ynfmu2-0"
})(["&&{box-shadow:0 1.6rem 4rem 0 rgba(59,118,160,0.08),0 4rem 13rem 0 rgba(59,118,160,0.16);height:45vh;margin-left:calc(var(--wrapperPadding) * -1);width:100vw;@media screen and (min-width:", "){height:55vh;}@media screen and (min-width:", "){margin-left:0;width:100%;min-height:90rem;}@media screen and (min-width:", "){margin-top:calc(var(--sectionPadding) * -2);}}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.m);