"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const TextClamp = ({
  text,
  maxLength = 246,
  variant = 'large',
  alwaysClamp = false,
  style
}) => {
  const {
    open,
    onToggle
  } = (0, _useSesame.default)(text.length < maxLength);
  const ref = (0, _react.useRef)(null);
  const containerRef = (0, _react.useRef)(null);
  return __jsx("div", {
    ref: containerRef,
    style: style
  }, __jsx(Inner, {
    variant: variant,
    open: open,
    alwaysClamp: alwaysClamp
  }, text), text.length > maxLength && __jsx(StyledButton, {
    ref: ref,
    variant: "outline",
    onClick: () => {
      if (open) {
        onToggle();
        if (containerRef.current && containerRef.current?.getBoundingClientRect().top < 0) {
          setTimeout(() => ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 100);
        }
      } else {
        onToggle();
      }
    },
    alwaysClamp: alwaysClamp
  }, !open && __jsx(_reactIntl.FormattedMessage, {
    id: "EbRgER",
    defaultMessage: [{
      "type": 0,
      "value": "Lees meer"
    }]
  }), open && __jsx(_reactIntl.FormattedMessage, {
    id: "oBgVzm",
    defaultMessage: [{
      "type": 0,
      "value": "Lees minder"
    }]
  })));
};
const Inner = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "TextClamp__Inner",
  componentId: "pr7ntk-0"
})(["max-height:", ";position:relative;overflow:hidden;", ";:after{content:'';position:absolute;bottom:0;left:0;width:100%;height:5.6rem;background:linear-gradient(-180deg,rgba(255,255,255,0) 0%,rgb(255,255,255) 100%);transition:opacity 0.3s ease;opacity:", ";transform:translateZ(0);", ";}"], ({
  open
}) => open ? 'unset' : 'calc(2rem * 7)', ({
  alwaysClamp
}) => !alwaysClamp && (0, _styledComponents.css)(["@media (min-width:", "){max-height:unset;}"], ({
  theme
}) => theme.mediaQueries.s), ({
  open
}) => open ? '0' : '1', ({
  alwaysClamp
}) => !alwaysClamp && (0, _styledComponents.css)(["@media (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.s));
const StyledButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "TextClamp__StyledButton",
  componentId: "pr7ntk-1"
})(["margin-top:2.4rem;", ";"], ({
  alwaysClamp
}) => !alwaysClamp && (0, _styledComponents.css)(["@media (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.s));
var _default = exports.default = TextClamp;