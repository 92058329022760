"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _TextClamp = _interopRequireDefault(require("./designsystem/TextClamp"));
var _Marker = _interopRequireDefault(require("./svg/Marker.svg"));
var _Rating = _interopRequireDefault(require("./Rating"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
const _excluded = ["rating", "title", "tagline", "description", "withMarker"];
var __jsx = _react.default.createElement;
const Intro = _ref => {
  let {
      rating,
      title,
      tagline,
      description,
      withMarker
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(StyledCell, (0, _extends2.default)({
    pb: [null, null, 5]
  }, props), __jsx(_Heading.default, null, rating && __jsx(_Rating.default, {
    value: rating
  }), __jsx(StyledTitle, {
    variant: "small"
  }, withMarker && __jsx(StyledMarker, null), title, __jsx("br", null), __jsx(Tagline, null, tagline))), description && __jsx(_TextClamp.default, {
    maxLength: 200,
    text: description,
    variant: "huge"
  }));
};
var _default = exports.default = Intro;
const Tagline = _styledComponents.default.span.withConfig({
  displayName: "Intro__Tagline",
  componentId: "sc-13koirw-0"
})(["display:block;padding-top:", ";", ";"], ({
  theme
}) => theme.spacing['20_Tiny'], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.title.large));
const StyledCell = (0, _styledComponents.default)(_Cell.default).withConfig({
  displayName: "Intro__StyledCell",
  componentId: "sc-13koirw-1"
})(["max-width:", ";"], ({
  theme
}) => theme.maxWidths.l);
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "Intro__StyledTitle",
  componentId: "sc-13koirw-2"
})(["font-weight:400;"]);
const StyledMarker = (0, _styledComponents.default)(_Marker.default).withConfig({
  displayName: "Intro__StyledMarker",
  componentId: "sc-13koirw-3"
})(["margin-right:", ";"], ({
  theme
}) => theme.spacing['20_Tiny']);